<template>
    <div class="content" v-loading="loading">
        <div>
            <h1> <img :src="project.userAvatar || '//placehold.it/128x128'" class="mini-avatar" /> {{ project.name }} <br>
                <span style="font-size: 14px"> by {{project.username}} Published on {{project && prettyDate || 'NA'}}</span>
                <el-tag type="info" v-for="(tag,index) in project.tags" :key="index">{{tag}}</el-tag>
            </h1>
        </div>
        <el-row>
            <el-col :span="16" class="main-container">
                <el-card class="box-card">
                    <img class="main-img" :src="project.thumbnail">
                </el-card>
                <el-row class="mini-previews">
                    <el-col :span="6" v-for="(item,index) in projectPhotos" :key="index">
                        <img :src="item.url" class="mini-preview" @click="showGallery(index)" />
                    </el-col>
                </el-row>
            </el-col>

            <el-col :span="8">

                <div id="downloads-widget">
                    <div class="label">
                        <img src="../assets/cloud-computing.png" /><br> DOWNLOADS
                    </div>
                    <div class="number">
                        {{ project.downloadsCount || 0}}
                    </div>
                </div>

                <div id="comments-widget">
                    <div class="label">
                        <img src="../assets/chat.png" />
                        <br>COMMENTS
                    </div>
                    <div class="number">
                        {{ project.commentsCount || 0}}
                    </div>
                </div>

                <!-- <div id="featured-widget">
                                                                                                                                                                                                                                                                                        <div class="label">
                                                                                                                                                                                                                                                                                            <img src="../assets/star.png" />
                                                                                                                                                                                                                                                                                            <br>FEATURED
                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                    </div> -->
                <div class="clear"></div>

                <div>
                    <p>
                        <el-button class="full" type="warning" icon="el-icon-menu" v-show="project.category" @click="showCategory(project.category)">{{'Category: ' + project.category}}</el-button>
                    </p>
                    <p>
                        <el-button class="full" type="info" icon="el-icon-information" v-if="project.website" @click="showDocumentation">Project Documentation</el-button>
                        <el-button class="full" icon="el-icon-information" v-else :disabled="true">No documentation</el-button>
                    </p>
                    <p>
                        <el-button class="full" type="success" icon="el-icon-circle-check" v-if="project.files && project.files.length" @click="showDownloads">Download this design</el-button>
                        <el-button class="full" icon="el-icon-circle-check" v-else :disabled="true">No downloads</el-button>
                    </p>
                    <p>
                        <el-button class="full" type="primary" icon="el-icon-edit" v-show="currentUserId === project.userId" @click="editProject">Edit project</el-button>
                    </p>
                    <p>
                        <el-button class="full" type="danger" icon="el-icon-delete" v-show="currentUserId === project.userId" @click="deleteProject">Delete project</el-button>

                    </p>

                </div>
            </el-col>
        </el-row>
        <div class="about">
            {{ project.description}}
        </div>
        <h3>Comments</h3>
        <p v-if="!project.comments || project.comments.length == 0">No comments yet</p>
        <div v-else>
            <el-card v-for="comment in project.comments" :key="comment['.key']" class="comment-item">
                <div slot="header" class="clearfix">
                    <span><img :src="comment.userAvatar" class="mini-avatar" /> {{comment.username}}<br>
                        <small>{{comment.created}}</small>
                    </span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">Operation button</el-button> -->
                </div>
                <div class="text item">
                    {{comment.body}}
                </div>
            </el-card>
        </div>
        <el-form :model="commentForm" v-if="currentUserId != 'na'">
            <el-form-item>
                <el-input type="textarea" :rows="2" placeholder="Please write here your comment" v-model="commentForm.textarea">
                </el-input>
            </el-form-item>
            <p>
                <el-button @click="addComment" icon="el-icon-comment">Add Comment</el-button>
            </p>
        </el-form>
        <div v-else>
            <p>You must be
                <router-link :to="{name: 'login'}">logged in</router-link> to add a comment</p>
        </div>
        <el-dialog :visible.sync="galleryVisible" :center="true">
            <el-carousel :autoplay="false" arrow="always" ref="carousel" width="512" height="512" :initial-index="galleryIdx">
                <el-carousel-item :span="6" v-for="(item,index) in projectPhotos" :key="index">
                    <img :src="item.url" />
                </el-carousel-item>
            </el-carousel>
        </el-dialog>
        <el-dialog :visible.sync="downloadVisible">
            <div slot="title">
                Download project files
            </div>
            <div class="license">
                <h3>Licensing terms:</h3>
                <p>By downloading the files you accept the following licensing terms:</p>
                <h4>
                    <strong>Creative Commons Attribution Non commercial</strong>
                </h4>
            </div>
            <div class="downloads">
                <h3>Downloads:</h3>
                <p>Click on the files to download:</p>
                <ul class="filelist">
                    <li v-for="(file,index) in project.files" :key="index">

                        <i class="el-icon-document"></i>
                        <a href="#" @click="downloadFile(file)">{{file.name}}
                        </a>
                        <small>(size {{ Math.ceil(file.size / 1024) + ' kb' }})</small>

                    </li>
                </ul>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import moment from 'moment'

let db, projectRef

export default {
    data() {
        return {
            commentForm: {
                textarea: '',
            },

            loading: true,
            project: {},
            downloadVisible: false,
            galleryVisible: false,
            galleryIdx: 0,
            downloadRecorded: false,
            currentUser: null
        }
    },
    created() {
        const _this = this
        db = firebase.database().ref()

        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                // User is signed in.
                _this.currentUser = user
                // _this.$forceUpdate()
            } else {
                // No user is signed in.
                _this.currentUser = null
            }
        });
    },
    mounted() {
        const _this = this
        projectRef = db.child('/library/projects/' + this.$route.params.id)
        this.$bindAsObject('project', projectRef, null, function(data) {
            _this.loading = false
            _this.$nextTick(function() {
                const userId = _this.project.userId || null
                if (userId == null) {
                    _this.$router.push('/not-found')
                }
            })
        })

    },
    computed: {
        currentUserId() {

            if (this.currentUser) return this.currentUser.uid
            return 'na'
        },
        prettyDate() {
            if (this.project) return moment(this.project.created).format('MMMM DD YYYY')
            return ''
        },
        projectPhotos() {
            if (this.project.photos) {
                return this.project.photos.filter((item) => item.url);
            }

            return [];
        },
    },
    methods: {
        resetForm() {
            this.commentForm = {
                textarea: ''
            }
        },
        showDocumentation() {
            if (this.project.website) {
                window.open(this.project.website, '_blank')
            }
        },
        addComment() {
            const _this = this;
            if (this.commentForm.textarea == '') {
                this.$message("Can't send empty comment")
                return
            }
            if (this.commentForm.textarea.length < 10 || this.commentForm.length > 500) {
                this.$message("Your comment must be between 10 and 500 characters")
                return
            }
            const user = firebase.auth().currentUser
            const created = new Date().toUTCString()
            const commentsUpdate = {
                projectId: this.project['.key'],
                username: user.displayName,
                userAvatar: user.photoURL,
                userId: user.uid,
                body: this.commentForm.textarea,
                created: created
            }

            const newKey = this.$firebaseRefs.project.child('comments').push().key

            let updates = {}
            const root = 'library'
            const projectPath = '/projects/' + this.project['.key']
            const profilePath = '/profiles/' + user.uid
            const newCount = (this.project.commentsCount || 0) + 1
            updates[root + projectPath + '/comments/' + newKey] = commentsUpdate
            updates[root + projectPath + '/commentsCount'] = newCount
            updates[root + profilePath + '/comments'] = commentsUpdate
            updates[root + profilePath + projectPath + '/comments/' + newKey] = commentsUpdate
            updates[root + profilePath + projectPath + '/commentsCount'] = newCount

            db.update(updates).then(function(success) {

                _this.$message('Comment inserted')
                _this.resetForm()
                // _this.$refs.textarea.

            }).catch(function(error) {
                _this.$message('Failed to insert comment')
            })
        },
        deleteProject() {
            const _this = this
            this.$confirm('This will permanently delete the project. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then((success) => {
                //TODO: DELETE

                let updates = {}
                const root = 'library'
                const projectKey = this.project['.key']
                const user = firebase.auth().currentUser

                updates['/library/projects/' + projectKey] = null
                updates['/library/downloaded/' + projectKey] = null
                updates['/library/downloaded/' + projectKey] = null
                updates['/library/profiles/' + user.uid + '/projects/' + projectKey] = null
                updates['/library/recent/' + projectKey] = null
                updates['/library/downloaded/' + projectKey] = null
                updates['/library/featured/' + projectKey] = null
                db.update(updates).then(function(success) {

                    _this.$message({
                        type: 'success',
                        message: 'Delete completed'
                    });
                    _this.$router.push('/profile')

                }).catch(function(error) {
                    _this.$message('Failed to delete project')
                })

            }).catch((err) => {
                // console.log(err)
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        showCategory(cat) {
            // TODO: SHOW CATEGORY
            this.$router.push({ name: 'catalogue', params: { category_id: cat } })
        },
        showDownloads() {
            this.downloadVisible = true
        },
        editProject() {
            // TODO EDIT PROJECT FORM
            if (firebase.auth().currentUser.uid == this.project['userId'])
                this.$router.push('/project/' + this.project['.key'] + '/edit')
            else
                this.$message('You don\'t have the rights to edit this project')
        },
        showGallery(index) {
            this.galleryVisible = true
            const _this = this
            this.$nextTick(function() {
                _this.$refs.carousel.setActiveItem(index)
            })
        },
        downloadFile(file) {
            if (this.downloadRecorded) {
                return window.open(file.url, file.name)
            }
            const _this = this
            let updates = {}
            let projectCopy = Object.assign({}, this.project)
            delete projectCopy['.key']
            projectCopy.downloaded = new Date().toUTCString()
            projectCopy.downloadsCount = (this.project.downloadsCount || 0) + 1
            const projectKey = '/library/projects/' + this.project['.key']
            updates[projectKey + '/downloadsCount'] = projectCopy.downloadsCount
            updates[projectKey + '/downloaded'] = projectCopy.downloaded
            updates['/library/downloaded/' + this.project['.key']] = projectCopy
            db.update(updates).then(function(success) {
                _this.downloadRecorded = true
                var link = document.createElement('a');
                link.href = file.url
                link.download = file.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(function(error) {
                _this.$message('Failed to download file')
            })
        }
    }
}
</script>
<style>
.icon {
    width: 32px;
    vertical-align: middle;
    margin-right: 12px;
}

.mini-avatar {
    vertical-align: middle;
    float: left;
    margin-right: 12px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
}

#downloads-widget,
#comments-widget,
#featured-widget {
    float: left;
    /* background-color: #dfdfaf; */
    text-align: center;
    width: auto;
    height: 130px;
    border: 1px solid #dedede;
    padding: 24px;
    margin-bottom: 24px;
    margin-right: 24px;
}

#downloads-widget .label>img,
#comments-widget .label>img,
#featured-widget .label>img {
    width: 64px;
    margin-left: auto;
    margin-right: auto;
}

#downloads-widget .label,
#comments-widget .label,
#featured-widget .label {
    font-size: 12px;
    color: #333;
}

#downloads-widget .number,
#featured-widget .number,
#comments-widget .number {
    font-size: 36px;
    font-weight: lighter;
    color: #333;
}

.clear {
    clear: both;
}

.main-img,
.mini-preview {
    width: 100%
}


.mini-previews .el-col {
    padding: 6px;
    padding-top: 10px;
    border: 1px solid #dedede;
    margin-top: 12px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.main-container {
    padding-top: 5px;
    padding-bottom: 15px;
    padding-right: 24px;
}

.el-dialog {
    height: 650px;
}

.el-carousel {
    /* min-height: 60vh; */
    height: 512px;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
    text-align: center;
}

.el-carousel__item {
    text-align: center;
    min-height: 512px;
}

.el-carousel__item img {
    width: 512px;
    height: 512px;
    margin-left: auto;
    margin-right: auto;
}

.el-carousel__item:nth-child(2n) {
    /* background-color: #99a9bf; */
}

.el-carousel__item:nth-child(2n+1) {
    /* background-color: #d3dce6; */
}

.el-carousel__arrow {
    top: 250px;
}

.filelist {}

.filelist li {
    height: 32px;
    list-style: none;
    margin-left: -36px;
    font-size: 16px;
}

.comment-item {
    margin-bottom: 12px;
}

.full {
    width: 100%;
}

.about {
    min-height: 200px;
    border-left: 5px solid #dedede;
    padding-left: 24px;
    font-size: 1.1em;
}
</style>
